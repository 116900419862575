export const ScrollTo = (e: React.MouseEvent<HTMLAnchorElement>) => {
  e.preventDefault();

  const element = window.document.getElementById(e.currentTarget.href.split("#")[1]);

  if (!element) {
    return;
  }

  element.scrollIntoView({ behavior: "smooth" });
};