import appsettingsLocal from "appSettings/appSettings.local";
import appsettingsDev from "appSettings/appSettings.dev";
import appsettingsProd from "appSettings/appSettings.prod";

export interface IAppSettings {
  apiUrl: string;
}

const getConfiguration = (): IAppSettings => {
  switch(process.env.REACT_APP_ENVIRONMENT?.toLowerCase()) {
    case "dev":
      return appsettingsDev;
    case "prod":
      return appsettingsProd;
    default:
      return appsettingsLocal;
  }
}

const AppSettings: IAppSettings = getConfiguration();

console.log(AppSettings);

export default AppSettings;