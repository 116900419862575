import { Container, Stack, Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import DevicesIcon from '@mui/icons-material/Devices';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import ImageIcon from '@mui/icons-material/Image';
import DescriptionIcon from '@mui/icons-material/Description';

import styles from './Features.module.css';

const Features = () => {
  const { t } = useTranslation();

  return (
    <Container maxWidth="xl" id={t('topMenuFeaturesSlug')}>
      <Stack spacing={1} className={styles['features-heading']}>
        <Typography variant="h2">
          {t('featuresHeadline')}
        </Typography>

        <Typography variant="subtitle1" component="h3">
          {t('featuresSubHeadline')}
        </Typography>
      </Stack>

      <Stack direction="row" flexWrap="wrap" spacing={3} className={styles['features-cards']} sx={{rowGap: 6}}>
        <Stack direction="row" flexWrap="wrap" spacing={3} className={styles['features-card-group']} sx={{rowGap: 6}}>
          <Box className={styles['features-card']}>
            <Box m={1}>
              <DevicesIcon fontSize="large" color="primary"  />
            </Box>
            <FeatureCard heading={t('featureCard1Headline')} text={t('featureCard1Text')} />
          </Box>
          <Box className={styles['features-card']}>
            <Box m={1}>
              <LocationOnIcon fontSize="large" color="primary" />
            </Box>
            <FeatureCard heading={t('featureCard2Headline')} text={t('featureCard2Text')} />
          </Box>
        </Stack>
        <Stack direction="row" flexWrap="wrap" spacing={3}  className={styles['features-card-group']} sx={{rowGap: 6}}>
          <Box className={styles['features-card']}>
            <Box m={1}>
              <ImageIcon fontSize="large" color="primary" />
            </Box>
            <FeatureCard heading={t('featureCard3Headline')} text={t('featureCard3Text')} />
          </Box>
          <Box className={styles['features-card']}>
            <Box m={1}>
            <DescriptionIcon fontSize="large" color="primary" />
            </Box>
            <FeatureCard heading={t('featureCard4Headline')} text={t('featureCard4Text')} />
          </Box>
        </Stack>
      </Stack>
    </Container>
  );
};

function FeatureCard({ heading, text }: { heading: string, text: string}) {
  return (
    <Box className={styles['benefit-card']}>
      <Stack className={styles['benefit-card-text-container']} spacing={2}>
        <Typography variant="subtitle1" component="h4">
          {heading}
        </Typography>

        <Typography variant='body1' sx={{whiteSpace: "pre-wrap"}}>
          {text}
        </Typography>
      </Stack>
    </Box>
  );
}

export default Features;