import { ReactNode } from 'react';
import { Container, Box, Stack, Button, Typography, List, ListItem } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ScrollTo } from '../../../../common/scrolling';

import styles from './Benefits.module.css';
import imageAddRemark from './add_remark.webp';
import imageReportInspection from './report_inspection.webp';
import imageCommunication from './communication.webp';

export default function Benefits() {
  const { t } = useTranslation();

  return (
    <Container maxWidth="xl" id={t('topMenuBenefitsSlug')}>
      <Stack spacing={1} className={styles['benefit-heading']}>
        <Typography variant="h2">
            {t('benefitsHeadline')}
        </Typography>

        <Typography variant="subtitle1" component="h3">
            {t('benefitsSubHeadline')}
          </Typography>
      </Stack>

      <Box className={styles['benefit-cards']}>
        <Stack direction="row" flexWrap="wrap" className={styles['benefit-card-stack']}>
            <ImageBox src={imageAddRemark}/>
            <BenefitTextBox
              heading={t('benefitsCard1Headline')}
              listItem1={t('benefitsCard1ListItem1')}
              listItem2={t('benefitsCard1ListItem2')}
              listItem3={t('benefitsCard1ListItem3')}
              buttonText={t('benefitsCard1ButtonText')}
              buttonSlug={t('benefitsCard1ButtonSlug')}
            >
              {t('benefitsCard1Text')}
            </BenefitTextBox>
        </Stack>

        <Stack direction="row" flexWrap="wrap-reverse" className={styles['benefit-card-stack']}>
          <BenefitTextBox
            heading={t('benefitsCard2Headline')}
            listItem1={t('benefitsCard2ListItem1')}
            listItem2={t('benefitsCard2ListItem2')}
            listItem3={t('benefitsCard2ListItem3')}
            buttonText={t('benefitsCard2ButtonText')}
            buttonSlug={t('benefitsCard2ButtonSlug')}
          >
            {t('benefitsCard2Text')}
          </BenefitTextBox>
          <ImageBox src={imageCommunication} />
        </Stack>

        <Stack direction="row" flexWrap="wrap" className={styles['benefit-card-stack']}>
          <ImageBox src={imageReportInspection} />
          <BenefitTextBox
            heading={t('benefitsCard3Headline')}
            listItem1={t('benefitsCard3ListItem1')}
            listItem2={t('benefitsCard3ListItem2')}
            listItem3={t('benefitsCard3ListItem3')}
            buttonText={t('benefitsCard3ButtonText')}
            buttonSlug={t('benefitsCard3ButtonSlug')}
          >
            {t('benefitsCard3Text')}
          </BenefitTextBox>
        </Stack>
      </Box>
    </Container>
  );
};

type BenefitTextBoxProps = {
  children: ReactNode,
  heading: string,
  listItem1: string,
  listItem2: string,
  listItem3: string,
  buttonText: string,
  buttonSlug: string
}

function BenefitTextBox({ children, heading, listItem1, listItem2, listItem3, buttonText, buttonSlug }: BenefitTextBoxProps) {
  return (
    <Box className={styles['benefit-card']}>
      <Stack spacing={2} className={styles['benefit-textbox-stack']}>
        <Typography variant="subtitle1" component="h3">
          {heading}
        </Typography>
        <List sx={{ listStyleType: 'disc', '& .MuiListItem-root': { display: 'list-item', }, ml: 3, pl: 0 }}>
          <ListItem>{listItem1}</ListItem>
          <ListItem>{listItem2}</ListItem>
          <ListItem>{listItem3}</ListItem>
        </List>
        <Typography variant='body1' sx={{whiteSpace: "pre-wrap"}}>
          {children}
        </Typography>

        <Button
          disableElevation
          variant="contained"
          href={buttonSlug}
          onClick={(e) => ScrollTo(e)}
          sx={{
            maxWidth: {
              xs: '100%',
              sm: '165px'
            }
          }}
        >
          {buttonText}
        </Button>
      </Stack>
    </Box>
  );
}

function ImageBox({ src } : {src: string}) {
  return (
    <Box className={styles['benefit-card']}>
      <img alt="placeholder" src={src} style={{width: '100%', maxWidth: '720px'}} />
    </Box>
  );
}