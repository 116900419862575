import { Button, Container, Typography, Box, Stack} from '@mui/material';
import { t } from "i18next";
import { ScrollTo } from '../../../../common/scrolling'

import styles from './Hero.module.css';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import edit_remark from './edit_remark2.webp';

const Hero = () => {
  return (
    <div id="start" className={styles['full-width-background']}>
      <Container maxWidth="xl"  className={styles['container']}>
        <Box className={styles['image-container']} sx={{
          display: {
            xs: 'none',
            md: 'block'
          }
        }}>
          <img src={edit_remark} alt="" className={styles['hero-image']} />
        </Box>

        <Box className={styles['text-container']} sx={{
          width: {
            xs: '100%',
            md: '60%'
          }
        }}>
          <Typography variant="h1" sx={{
            textAlign: {
              xs: 'center',
              md: 'left'
            }
          }}>
            {t('heroHeadline')} 
          </Typography>

          <Typography component="h2" variant="h3" sx={{
            textAlign: {
              xs: 'center',
              md: 'left'
            }
          }}>
            {t('heroSubHeadline')}
          </Typography>

          <Box className={styles['actions']} sx={{
            justifyContent: {
              xs: 'center',
              md: 'left'
            }
          }}>
            <Button
              variant="contained"
              disableElevation
              href={"#"+t('topMenuOrderSlug')}
              onClick={(e) => ScrollTo(e)}
            >
              {t('heroButtonOrder')}
            </Button>

            <Button
              variant="outlined"
              disableElevation
              href={"#"+t('topMenuBenefitsSlug')}
              onClick={(e) => ScrollTo(e)}
            >
              {t('heroButtonMore')}
            </Button>
          </Box>

          <Stack direction="row" alignItems="center" gap={1} sx={{
            justifyContent: {
              xs: 'center',
              md: 'left'
            }
          }}>
            <CheckCircleIcon />
            <Typography variant="body1">
              {t('heroMoneyBackText')}
            </Typography>
          </Stack>
        </Box>
      </Container>
    </div>
  );
};

export default Hero;