import { Container, Stack, Box, Typography, Avatar } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Carousel from 'react-material-ui-carousel'
import styles from './Demo.module.css';
//import videoplaceholder from './videoplacholder.svg';
import d1 from './add_inspection.webp';
import d2 from './perform_inspection.webp';
import d3 from './review_inspection.webp';
import d4 from './report_inspection.webp';

const Demo = () => {
  const { t } = useTranslation();

  var items = [
    {
        name: d1,
    },
    {
        name: d2,
    },
    {
        name: d3,
    },
    {
        name: d4,
    }
  ]

  return (
    <Container maxWidth="xl" id={t('topMenuDemoSlug')}>
      <Box className={styles['demo-heading']}>
        <Typography variant="h2">
          {t('demoHeadline')}
        </Typography>
      </Box>

      <Box className={styles['video-container']}>
      <Carousel sx={{width: '1488px'}} autoPlay={true} navButtonsAlwaysVisible={true} interval={5000}>
            {
                items.map( (item, i) => <Item key={i} name={item.name} /> )
            }
      </Carousel>
      </Box>

      <Stack direction="row" flexWrap="wrap" spacing={3} className={styles['demo-cards']}>
        <DemoCard avatarText="1" heading={t('demoCard1Headline')} text={t('demoCard1Text')} />
        <DemoCard avatarText="2" heading={t('demoCard2Headline')} text={t('demoCard2Text')} />
        <DemoCard avatarText="3" heading={t('demoCard3Headline')} text={t('demoCard3Text')} />
      </Stack>
    </Container>
  );
};

const DemoCard = ({ avatarText, heading, text }: { avatarText: string, heading: string, text: string}) => {
  return (
    <div className={styles['demo-card']}>
      <Avatar className={styles['demo-avatar']} sx={{ fontSize: '1.5rem', width: 64, height: 64 }}>{avatarText}</Avatar>

      <div className={styles['demo-card-text-container']}>
        <Typography variant="subtitle2" component="h3">
          {heading}
        </Typography>

        <Typography variant='body2'>
          {text}
        </Typography>
      </div>
    </div>
  );
}
//         <img alt="How it works" src={videoplaceholder} style={{width: '100%', maxWidth: '750px'}} />

function Item({ key, name }: { key: number, name: string })
{
    return (
<img alt="mobile" src={name} />
    )
}

export default Demo;