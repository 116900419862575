import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';

import logo from '../../assets/images/logo.png';
import styles from './Footer.module.css';

const Footer = () => {
  const { t } = useTranslation();

  return (
    <div className={styles['container']}>
      <div className={styles['content']}>
        <div>
          <a href={window.location.pathname}><img src={logo} alt="Emendly" /></a>
        </div>
        <div>
          <Typography variant="body1">
          &copy; {t('footerCopywriteText')}
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default Footer;