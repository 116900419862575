import { Dispatch, SetStateAction, useState, useEffect } from 'react';
import { Modal, IconButton, Container, Box, Typography, Stack, Divider, TextField, Button, Alert } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';
import { isEqual } from 'lodash';
import appSettings from 'appSettings/appSettings';
import axios from 'axios';

import styles from './OrderModal.module.css';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloseIcon from '@mui/icons-material/Close';

export default function Order(props: {opened: boolean, setModalOpen: Dispatch<SetStateAction<boolean>>}) {
  useEffect(() => {
    setHasSubmitted(false);
  }, [props.opened]);

  const { t } = useTranslation();

  const [hasSubmitted, setHasSubmitted] = useState(false);

  return (
    <Modal
      open={props.opened}
      onClose={() => props.setModalOpen(false)}
      className={styles['order-modal']}
    >
      <Stack direction="row" className={styles["modal-stack"]} sx={{
        flexDirection: {
          md: 'row',
          xs: 'column'
        }
      }}>

        <Box className={styles['text-container']} sx={{
          width: {
            md: '50%',
            xs: '100%;'
          },
          alignItems: {
            md: 'end',
            xs: 'center'
          }
        }}>
          <Stack gap={7} className={styles["text-stack"]}>
            <Typography variant="h2">
              {t('orderModalTitle')}
            </Typography>

            <Typography variant="subtitle1">
              {t('orderModalBody')}
            </Typography>

            <Stack gap={3} alignItems="start">
              <BulletPoint text={t('orderModalListItem1')} />
              <BulletPoint text={t('orderModalListItem2')} />
              <BulletPoint text={t('orderModalListItem3')} />
              <BulletPoint text={t('orderModalListItem4')} />
              <BulletPoint text={t('orderModalListItem5')} />
            </Stack>

            <Stack gap={2} sx={{maxWidth: '650px'}}>
              <Divider />

              <Typography variant="subtitle1">
                {t("orderModalWhyText")}
              </Typography>
            </Stack>
          </Stack>
        </Box>
        
        <Box className={styles['form-container']} sx={{
          width: {
            md: '50%',
            xs: '100%;'
          },
          alignItems: {
            md: 'start',
            xs: 'center'
          }
        }}>
          <Stack className={styles['form-stack']}>
            { hasSubmitted
              ? <BookingComplete toggleModal={props.setModalOpen} />
              : <BookADemoForm setHasSubmitted={setHasSubmitted} />
            }

            <Container className={styles['modal-close-button']}>
              <IconButton aria-label="Close" component="a" onClick={() => props.setModalOpen(false)}>
                <CloseIcon />
              </IconButton>
            </Container>          
          </Stack>
        </Box>

      </Stack>
    </Modal>
  );
}

const BulletPoint = ({text} : {text: string}) => {
  return (
    <Stack direction="row" alignItems="center" gap={3} sx={{
      justifyContent: {
        xs: 'center',
        md: 'left'
      }
    }}>
      <CheckCircleIcon />
      <Typography variant="subtitle1">
        {text}
      </Typography>
    </Stack>
  );
}

const BookingComplete = ({toggleModal}: {toggleModal: Dispatch<SetStateAction<boolean>>}) => {

  const { t } = useTranslation();

  return (
    <Stack gap={2} textAlign="center">
      <Typography variant="subtitle1">
        {t('orderModalFormSubmittedTitle')}
      </Typography>

      <Typography variant="body1">
        {t('orderModalFormSubmittedBody')}
      </Typography>

      <Button
        onClick={() => toggleModal(false)}
        variant="contained"
        sx={{
          maxWidth: '150px',
          width: '100%',
          margin: '0 auto'
        }}
      >
        {t('orderModalFormSubmittedButton')}
      </Button>
    </Stack>
  );
}

const BookADemoForm = (props: {setHasSubmitted: Dispatch<SetStateAction<boolean>>}) => {

  const { t } = useTranslation();

  const formSpecification = {
    name: '',
    email: '',
    phone: '',
    tellUsAboutYourBusiness: ''
  };

  const [formData, setFormData] = useState({...formSpecification});
  const [errors, setErrors] = useState({...formSpecification});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [hasFailed, setHasFailed] = useState(false);

  const handleFieldChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    setFormData({ ...formData, [name]: value });
  };

  const ValidateForm = (): boolean => {
    let validationErrors = {...formSpecification};

    if (formData.name.length === 0) {
      validationErrors = {...validationErrors, name: t('orderModalFormValidationErrorNameRequired')};
    }

    const emailValidationRegex: RegExp = /^[-!#$%&'*+/0-9=?A-Z^_a-z{|}~](\.?[-!#$%&'*+/0-9=?A-Z^_a-z{|}~])*@[a-zA-Z](-?[a-zA-Z0-9])*(\.[a-zA-Z](-?[a-zA-Z0-9])*)+$/;
    if (formData.email.length === 0) {
      validationErrors = {...validationErrors, email: t('orderModalFormValidationErrorEmailRequired')};
    } else if (!formData.email.match(emailValidationRegex)) {
      validationErrors = {...validationErrors, email: t('orderModalFormValidationErrorEmailInvalid')};
    }

    setErrors({...errors, ...validationErrors});

    const isValid = isEqual(validationErrors, formSpecification);

    return isValid;
  };

  const handleSubmit = (event: React.FormEvent): void => {
    event.preventDefault();

    if (!ValidateForm())
    {
      return;
    }

    const callApi = async () => {
      try {
        setIsSubmitting(true);

        const client = axios.create({
          baseURL: appSettings.apiUrl,
          headers: {
            'Content-Type': 'application/json'
          }
        });

        await client.post("demobooking", {
          name: formData.name,
          email: formData.email,
          phone: formData.phone,
          message: formData.tellUsAboutYourBusiness
        });

        props.setHasSubmitted(true);
        setHasFailed(false);
      } catch(error) {
        setHasFailed(true);
      } finally {
        setIsSubmitting(false);
      }
    };

    callApi();
  };

  return (
    <Stack className={styles['order-modal-form-stack']}>
      <form
        autoComplete="off"
        onSubmit={handleSubmit}
        className={styles['order-modal-form']}
        noValidate
      >
        <TextField
          name="name"
          label={t('orderModalFormFieldName')}
          onChange={handleFieldChange}
          required
          variant="outlined"
          type="text"
          sx={{
            maxWidth: '350px',
            width: '100%'
          }}
          value={formData.name}
          error={errors.name.length > 0}
          helperText={errors.name}
        />

        <TextField
          name="email"
          label={t('orderModalFormFieldEmail')}
          onChange={handleFieldChange}
          required
          variant="outlined"
          type="text"
          sx={{
            maxWidth: '450px',
            width: '100%'
          }}
          value={formData.email}
          error={errors.email.length > 0}
          helperText={errors.email}
        />

        <TextField
          name="phone"
          label={t('orderModalFormFieldPhone')}
          onChange={handleFieldChange}
          variant="outlined"
          type="text"
          sx={{
            maxWidth: '200px',
            width: '100%'
          }}
        />

        <TextField
          name="tellUsAboutYourBusiness"
          label={t('orderModalFormFieldTellUs')}
          onChange={handleFieldChange}
          variant="outlined"
          type="text"
          fullWidth
          multiline
          rows={4}
        />

        <LoadingButton
          variant="contained"
          type="submit"
          loading={isSubmitting}
        >
          {t('orderModalFormButtonSubmit')}
        </LoadingButton>
      </form>

      { hasFailed &&
        <Alert severity="error">
          {t('orderModalFormSubmitFailed')}
        </Alert>
      }
    </Stack>
  );
}