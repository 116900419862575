import { Box, Container, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Carousel from 'react-material-ui-carousel'

import styles from './Download.module.css';

import googleplay from './googleplay.svg';
import appstore from './appstore.svg';
import m1 from './m1.png';
import m2 from './m2.png';

const Download = () => {
  const { t } = useTranslation();

  return (
    <Box className={styles['container-background']} sx={{
      padding: {
        xs: '80px 0',
        md: '80px 0 0 0'
      }
    }}>
      <Container maxWidth="xl">
        <Box className={styles['content']} sx={{
          textAlign: {
            xs: 'center',
            md: 'unset'
          }
        }}>
          <Stack>
            <Typography variant="h2">
              {t('appHeadline')}
            </Typography>

            <Typography variant="body1">
              {t('appText')}
            </Typography>

            <Stack direction="row" spacing={2} flexWrap="wrap" className={styles['buttons']} sx={{
              justifyContent: {
                xs: 'center',
                md: 'left'  
              }
            }}>
              <img alt="Google" src={googleplay} />
              <img alt="Apple" src={appstore} />
            </Stack>
          </Stack>

          <Box sx={{
            display: {
              xs: 'none',
              md: 'flex'
            }
          }}>
            <PhoneCaruosel />
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

const PhoneCaruosel = () => {
  const images: string[] = [ m1, m2];

  return (
    <Carousel
      className={styles['phoneCaruosel']}
      autoPlay={true}
      indicators={false}
      stopAutoPlayOnHover={true}
      interval={6000}
      swipe={false}
    >
      {images.map((image, index) => (
        <img key={index} alt="Mobile app" src={image} />
      ))}
    </Carousel>
  )
}

export default Download;