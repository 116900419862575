import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationEN from './locales/en.json';
import translationSV from './locales/sv.json';

const resources = {
  en: {
    translation: translationEN
  },
  sv: {
    translation: translationSV
  }
};

let initLanguage = 'en';

if(window.location.pathname === "/sv-se/") {
  initLanguage = 'sv';
}

i18next.use(initReactI18next).init({
  lng: initLanguage, // if you're using a language detector, do not define the lng option
  resources: resources,
  keySeparator: false, // we do not use keys in form messages.welcome
  interpolation: {
    escapeValue: false // react already safes from xss
  }
});