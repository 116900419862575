import { createTheme, responsiveFontSizes } from '@mui/material/styles';

export const EmendlyTheme = () => {
  let theme = createTheme({
    palette: {
      primary: {
        main: '#424B5A'
      }
    },
    typography: {
      h1: {
        color: '#505D68',
        fontSize: '4.125rem',
        fontWeight: 400
      },
      h2: {
        color: '#505D68',
        fontSize: '3.125rem',
        lineHeight: 1.3,
        fontWeight: 700
      },
      h3: {
        color: '#505D68',
        fontSize: '2.0rem',
        fontWeight: 400
      },
      subtitle1: {
        color: '#505D68',
        fontSize: '1.3125rem',
        fontWeight: 700,
        lineHeight: 1.4
      },
      body1: {
        color: '#424B5A',
        fontSize: '1rem',
        fontWeight: '400',
        lineHeight: '1.5rem'
      },
      subtitle2: {
        color: '#505D68',
        fontSize: '1rem',
        fontWeight: 400,
        lineHeight: 1.5
      },
      body2: {
        color: '#A1AEB7',
        fontSize: '0.875rem',
        fontWeight: 400,
        lineHeight: 1.5
      }
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            minHeight: '40px'
          }
        }
      },
      MuiContainer: {
        styleOverrides: {
          root: {
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'center'
          }
        }
      },
      MuiStack: {
        defaultProps: {
          useFlexGap: true
        }
      },
      MuiFormLabel: {
        defaultProps: {
          required: false
        }
      }
    }
  });

  theme = responsiveFontSizes(theme);

  return theme;
}