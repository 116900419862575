import { useState, useEffect } from 'react';
import './App.css';

import ReactGA from "react-ga4";
import ScrollSpy from "react-ui-scrollspy";
import { useTranslation } from 'react-i18next';

import { ThemeProvider } from '@mui/material/styles';
import { EmendlyTheme } from './EmendlyTheme';

import Navbar from './components/navbar/Navbar';
import Footer from './components/footer/Footer';

import Hero from './/pages/start/sections/hero/Hero';
import Benefits from './pages/start/sections/benefits/Benefits';
import Features from './pages/start/sections/features/Features';
import Demo from './pages/start/sections/demo/Demo';
import Download from './pages/start/sections/download/Download';
import OrderModal from './pages/start/orderModal/OrderModal';
import Plans from './pages/start/sections/plans/Plans';

/* Use GA4 in production */
if (typeof window !== 'undefined' && window.location.host === 'emendly.com') {
  ReactGA.initialize("G-DYQG77XWSM");
  ReactGA.send("pageview");
  // Send pageview with a custom path
  //ReactGA.send({ hitType: "pageview", page: window.location.pathname });
}

function App() {
  const [activeSection, setActiveSection] = useState<string>('start');
  const [orderModelOpened, setOrderModalOpened] = useState<boolean>(false);

  const { t } = useTranslation();
  useEffect(() => { document.title = t('title') });

  return (
    <ThemeProvider theme={EmendlyTheme()}>
      <div className="app">
        <Navbar activeSection={activeSection} />
        <div className="sections">
          <ScrollSpy scrollThrottle={50} onUpdateCallback={s => setActiveSection(s)}>
            <Hero />
            <Benefits />
            <Features />
            <Demo />
            <Plans setModalOpen={setOrderModalOpened} />
            <Download />
          </ScrollSpy>
        </div>

        <OrderModal opened={orderModelOpened} setModalOpen={setOrderModalOpened} />
        <Footer />
      </div>
    </ThemeProvider>
  );
}

export default App;
