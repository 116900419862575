import { Dispatch, SetStateAction } from 'react';
import { Container, Box, Typography, Divider, Button, Stack } from '@mui/material';
import { t } from "i18next";

import CheckIcon from '@mui/icons-material/Check';
import styles from './Plans.module.css';

const Plans = (props: {setModalOpen: Dispatch<SetStateAction<boolean>>}) => {
  return(
    <Container maxWidth="xl" className={styles['plans']} id={t('topMenuOrderSlug')}>
        <Box sx={{
            width: '100%',
            textAlign: 'center'
        }}>
          <Typography variant="h2">{t('planHeading')}</Typography>
          <Typography variant="body1">{t('planSubheading')}</Typography>
        </Box>

        <Plan
          title={t('planInspectionTitle')}
          subTitle={t('planInspectionSubtitle')}
          description={t('planInspectionDescription')}
          bulletpoints={[t('planInspectionBulletPoints1'), t('planInspectionBulletPoints2'), t('planInspectionBulletPoints3'), t('planInspectionBulletPoints4')]}
          price={t('planInspectionPrice')}
          priceText={t('planInspectionPriceText')}
          buttonText={t('planInspectionButtonText')}
          toggleModal={props.setModalOpen}
          enabled={true}
        />

        <Plan
          title={t('planTaskTitle')}
          subTitle={t('planTaskSubtitle')}
          description={t('planTaskDescription')}
          bulletpoints={[t('planTaskBulletPoints1'), t('planTaskBulletPoints2'), t('planTaskBulletPoints3'), t('planTaskBulletPoints4')]}
          price={t('planTaskPrice')}
          priceText={t('planTaskPriceText')}
          buttonText={t('planTaskButtonText')}
          toggleModal={props.setModalOpen}
          enabled={false}
        />

        <Plan
          title={t('planPlannerTitle')}
          subTitle={t('planPlannerSubtitle')}
          description={t('planPlannerDescription')}
          bulletpoints={[t('planPlannerBulletPoints1'), t('planPlannerBulletPoints2'), t('planPlannerBulletPoints3'), t('planPlannerBulletPoints4')]}
          price={t('planPlannerPrice')}
          priceText={t('planPlannerPriceText')}
          buttonText={t('planPlannerButtonText')}
          toggleModal={props.setModalOpen}
          enabled={false}
        />
    </Container>
  );
}

type PlanProps = {
  title: string,
  subTitle: string,
  description: string,
  bulletpoints: string[],
  price: string,
  priceText: string,
  buttonText: string,
  toggleModal: Dispatch<SetStateAction<boolean>>,
  enabled: boolean
}

const Plan = ({title, subTitle, description, bulletpoints, price, priceText, buttonText, toggleModal, enabled}: PlanProps) => {
  let containerStyle = styles['plan-container'];

  if (!enabled) {
    containerStyle += ' ' + styles['plan-container-disabled']
  }

  return (
    <Box className={containerStyle}>
      <Box className={styles['description-container']}>
        <Typography variant="h2" component="h3">{title}</Typography>    
        <Typography variant="subtitle1" component="h4">{subTitle}</Typography>
        <Typography variant="body1">{description}</Typography>
      </Box>

      <Divider  />

      <Stack spacing={1}>
        {
          bulletpoints.map((bulletpoint, index) => {
            return (
              <Box key={index} className={styles['bulletpoint']}>
                <CheckIcon /><Typography>{bulletpoint}</Typography>
              </Box>
            );
          })
        }
      </Stack>

      <Divider />

      <Box className={styles['price']}>
        <Typography variant="h2" component="p">{price}</Typography>
        <Typography variant="body2" component="p">{priceText}</Typography>
      </Box>

      <Button disableElevation variant="contained" disabled={!enabled} fullWidth={true} onClick={() => toggleModal(true)}>
        {buttonText}
      </Button>
    </Box>
  );
}

export default Plans;