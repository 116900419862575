import React, { useState } from 'react';
import { AppBar, Box, Toolbar, Typography, Button, Container, IconButton, Menu, MenuItem } from '@mui/material';
import { t } from 'i18next';
import { ScrollTo} from '../../common/scrolling';

import styles from './Navbar.module.css';

import MenuIcon from '@mui/icons-material/Menu';
import logo from '../../assets/images/logo.png';

interface Section {
  buttonName: string;
  sectionName: string;
}

const Navbar = ({ activeSection }: { activeSection: string}) => { 
  const sections: Section[] = [
    { buttonName: t('topMenuStart'), sectionName: t('topMenuStartSlug') },
    { buttonName: t('topMenuBenefits'), sectionName: t('topMenuBenefitsSlug') },
    { buttonName: t('topMenuFeatures'), sectionName: t('topMenuFeaturesSlug') },
    { buttonName: t('topMenuDemo'), sectionName: t('topMenuDemoSlug') },
    { buttonName: t('topMenuOrder'), sectionName: t('topMenuOrderSlug') },
  ];

  return (
    <Box className={styles['top-menu']}>
      <AppBar position="fixed" color="default">
        <Container maxWidth="xl">
          <Toolbar disableGutters className={styles['toolbar']}>
            <div className="logo">
              <Container sx={{
                alignItems: 'center',
                display: {
                  xs: 'none',
                  md: 'flex'
                }, mr: 1 }}>
              <a href={window.location.pathname}><img src={logo} width="92" height="18" alt="Emendly" /></a>
              </Container>
            </div>
 
            <Desktop sections={sections} activeSection={activeSection} />
            <Mobile sections={sections} activeSection={activeSection} />

            <Container sx={{ alignItems: 'center', display: { xs: 'flex', md: 'none' }, mr: 1 }}>
              <img src={logo} alt="Emendly" />
            </Container>
          </Toolbar>
        </Container>
      </AppBar>
    </Box>
  );
}

const Desktop = ({ sections, activeSection }: { sections: Section[], activeSection: string}) => {
  return (
    <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
      {sections.map(({ buttonName, sectionName }) => (
        <Button
          data-to-scrollspy-id={sectionName}
          key={`desktop-${sectionName}`}
          onClick={(e) => ScrollTo(e)}
          href={"#"+sectionName}
          sx={{
            my: 1,
            mx: 1,
            color: '#000',
            backgroundColor: activeSection === sectionName ? '#fff' : '#F2F5F7',
            display: 'block',
            '&:hover': {
              backgroundColor: '#fff',
              color: '#333',
            },
          }}
        >
          {buttonName}
        </Button>
      ))}
    </Box>
  );
}

const Mobile = ({ sections, activeSection }: { sections: Section[], activeSection: string}) => {
  const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  return (
    <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
      <IconButton
        key="menu_small"
        size="large"
        aria-label="Expand menu"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={handleOpenNavMenu}
        color="inherit"
      >
        <MenuIcon key="menu_small_icon" />
      </IconButton>
      <Menu
        id="menu-appbar"
        anchorEl={anchorElNav}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        open={Boolean(anchorElNav)}
        onClose={handleCloseNavMenu}
        sx={{
          display: { xs: 'block', md: 'none' },
        }}
      >
        {sections.map(({ buttonName, sectionName }) => (
          <MenuItem
            component="a"
            key={'menu-appbar-' + sectionName}
            onClick={(e) => ScrollTo(e)}
            href={"#"+sectionName}
            sx={{
              color: '#000',
              backgroundColor: activeSection === sectionName ? '#F2F5F7' : '#fff',
            }}
          >
            <Typography textAlign="center">{buttonName}</Typography>
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
}

export default Navbar;